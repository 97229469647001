import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2811543174/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Programs`}</h1>
    <p>{`A `}<strong parentName="p">{`Program`}</strong>{` is a set of locations that uniquely represent an offline or online store where transactions from linked cards will be monitored. The Program is the parent object of your card linked structure. Other objects such as Cards, Locations, Webhooks and Transactions will always be linked to a Program. Brands are shared across Programs. They are unique and independent of each other allowing you to have several independent loyalty or rewards schemes inside your account.`}</p>
    <h5>{`Hierarchy diagram of the Fidel API object structure.`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/programs_diagram_2020.png",
        "alt": "Programs structure diagram",
        "title": "Programs structure diagram"
      }}></img></p>
    <h2>{`Create Program`}</h2>
    <p>{`To create a new program on the Fidel Dashboard, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/programs"
      }}><strong parentName="a">{`Programs`}</strong></a>{` page, click the `}<strong parentName="p">{`New program`}</strong>{` button and enter a name. The Program will be created with the name provided without any initial locations.`}</p>
    <h5>{`Go to the Programs page on the dashboard to create a new program.`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/create-program.png",
        "alt": "Create program",
        "title": "Create program"
      }}></img></p>
    <p>{`The same can be done with the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-program"
      }}>{`API`}</a>{`. Using curl, we can add 'Program X' as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST https://api.fidel.uk/v1/programs \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>' \\
  -d '{
    "name": "Program X",
    "metadata": {
      "customKey1": "customValue1",
      "customKey2": "customValue2"
    }
  }'
`}</code></pre>
    <p>{`A `}<inlineCode parentName="p">{`programId`}</inlineCode>{` will be generated and is used as a data property when using the SDKs to link Cards to this Program. Cards are linked to Programs and consequently are able to track all purchases at locations in the program.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      